@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;600;700&display=swap");

.App {
  font-family: "Open Sans", sans-serif;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

/* APP Flex Structure */
.structure {
  @apply flex flex-col items-center justify-start h-max min-h-screen pb-16 px-4 font-sans bg-fill-bg text-white;
}

.contentcontainer {
  @apply w-full max-w-xl;
}

/* Moontrade Logo CSS */
.moontradelogo-primary {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: bold;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.5))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

  @apply text-[2.5rem] lg:text-[4rem] leading-[0px] 
    text-white;
}

/* Page Title */

.titlestructure {
  @apply min-w-0 flex-1 py-4;
}

.titleheading {
  @apply text-2xl font-bold leading-7 text-header-primary sm:truncate sm:text-3xl sm:tracking-tight;
}

/* BUTTONS CSS */
.primary-cta-btn {
  @apply rounded bg-fill-primary px-8 py-3 w-[100%] my-[0.5rem] text-sm font-bold text-white transition focus:outline-none  hover:bg-indigo-600 active:bg-indigo-600 active:scale-95;
}

.secondary-cta-btn {
  @apply rounded bg-fill-secondary px-8 py-3 w-[100%] my-[0.5rem] text-sm font-bold text-white  transition focus:outline-none  active:bg-fuchsia-500 active:scale-95;
}

.neutral-btn-one {
  @apply rounded bg-transparent px-8 py-3 w-[100%] my-[0.5rem] text-sm font-bold text-txtcolor-neutral border border-slate-400 transition focus:outline-none  active:bg-slate-200 active:scale-95;
}

/* UTILITY BTNS */
.closebtn {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;

  @apply bg-transparent text-[hsl(239,100%,78%)] font-normal text-[.9rem] px-[.6rem] py-[.2rem] hover:text-orange-500 active:scale-[85%];
}

.smallfunctionbtn-primary {
  @apply rounded bg-fill-primary px-[.1rem] py-[.2rem] w-[2rem] h-[100%] my-[0.5rem] text-sm font-bold text-white transition focus:outline-none hover:bg-indigo-600 active:bg-indigo-500 active:scale-95;
}

.smallfunctionbtn-secondary {
  @apply rounded  bg-fill-secondary px-[.1rem] py-[.2rem] min-w-[50%] h-[100%] my-[0.5rem] text-sm font-bold text-white transition focus:outline-none  active:bg-fuchsia-500 active:scale-95;
}

.smallfunctionbtn-neutral {
  @apply rounded bg-transparent px-[.1rem] py-[.2rem] min-w-[50%] h-[100%] my-[0.5rem] text-sm font-bold text-slate-500 border border-slate-400 transition focus:outline-none active:bg-slate-200 active:scale-95;
}

/* TABLE CSS */
.table-responsive {
  @apply block w-full overflow-x-auto;
  -webkit-overflow-scrolling: touch;
}

table {
  @apply min-w-full border-collapse mb-4 divide-y divide-gray-300;
}

th {
  vertical-align: top;
  @apply py-3 px-2 bg-fill-tableheader;
}

td {
  vertical-align: top;
  @apply py-3 px-2 bg-fill-table;
}

thead th {
  @apply text-left text-sm font-semibold text-gray-900 whitespace-nowrap;
  vertical-align: center;
  border-bottom: 2px solid #c5c9ce;
}

tbody {
  @apply divide-y divide-gray-200 bg-white;

  tr {
    @apply odd:bg-gray-100;
    &:hover {
      @apply bg-gray-200;
    }
  }

  td {
    @apply py-2 px-2 text-sm whitespace-nowrap text-gray-500;
  }
}

table a {
  @apply text-indigo-600 hover:text-indigo-900 transition;
}

/* STATS DATA */

.statsflex {
  @apply mx-auto grid grid-cols-1 gap-px bg-gray-200 sm:grid-cols-2 lg:grid-cols-4 rounded-md;
}

.statsbox {
  @apply flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8 rounded-md;
}

.statsheader {
  @apply text-sm font-medium leading-6 text-gray-500;
}

.statsdata {
  @apply mt-1 text-xl font-semibold tracking-tight text-gray-800;
}

.statsneutral {
  @apply mt-1 text-xl font-semibold tracking-tight;
}

/* DESCRIPTION TABLE */

.desc-table-structure-header {
  @apply px-4 bg-white text-black p-4 mb-4;
}

.desc-table-structure-content {
  @apply mt-6 border-t border-gray-200;
}

.desc-table-row {
  @apply px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0;
}

.desc-table-header {
  @apply text-base font-semibold leading-7;
}

.desc-table-title {
  @apply text-sm font-medium leading-6;
}

.desc-table-text {
  @apply mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0;
}
