nav {
  @apply flex flex-row justify-around bg-slate-100 fixed bottom-0 w-screen py-3 align-middle;
}

nav a {
  @apply text-slate-900;
}

nav a.active {
  @apply text-txtcolor-primary font-bold;
}

nav a:hover {
  @apply text-txtcolor-primary scale-[130%];
}
