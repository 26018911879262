@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Open Sans", sans-serif;
}

@layer base {
  :root {
    /* Fills */
    --color-bg: 0deg 0% 100%;
    --color-primary: 239deg 82% 65%;
    --color-secondary: 292deg 99% 68%;
    --color-table: 0deg 0% 100%;
    --color-tbheader: 0deg 0% 100%;

    /* Header Colours */
    --header-pri: 239deg 62% 75%;

    /* Text Colours */
    --text-pri: 239deg 82% 65%;
    --text-secondary: 0deg 0% 40%;
    --text-neutral: 0deg 0% 30%;
  }

  /* This will respond to system preferences on load */
  @media (prefers-color-scheme: dark) {
    :root {
      /* Fills */
      --color-bg: 0deg 0% 100%;
      --color-primary: 239deg 82% 65%;
      --color-secondary: 292deg 99% 68%;
      --color-table: 0deg 0% 100%;
      --color-tbheader: 0deg 0% 100%;

      /* Header Colours */
      --header-pri: 239deg 62% 75%;

      /* Text Colours */
      --text-pri: 239deg 82% 65%;
      --text-secondary: 0deg 0% 40%;
      --text-neutral: 0deg 0% 30%;
    }
  }
  /* 
    This will respond to system preferences on load
  @media (prefers-color-scheme: dark) {
    :root {
      Fills
      --color-bg: 215deg 13% 13%;
      --color-bg: 0deg 0% 100%;
      --color-primary: 239deg 82% 65%;
      --color-secondary: 292deg 99% 68%;
      --color-table: 0deg 0% 95%;
      --color-tbheader: 239deg 82% 85%;

      Header Colours
      --header-pri: 239deg 62% 75%;

      Text Colours
      --text-pri: 239deg 82% 65%;
      --text-secondary: 0deg 0% 100%;
      --text-neutral: 0deg 0% 60%;
    }
  } */

  /* This is the forced toggle dark mode */
  :root[data-theme="dark"] {
    /* Fills */
    --color-bg: 215deg 13% 13%;
    --color-primary: 239deg 82% 65%;
    --color-secondary: 292deg 99% 68%;
    --color-table: 239deg 50% 95%;
    --color-tbheader: 239deg 82% 85%;

    /* Header Colours */
    --header-pri: 239deg 62% 75%;

    /* Text Colours */
    --text-pri: 239deg 82% 65%;
    --text-secondary: 0deg 0% 100%;
    --text-neutral: 0deg 0% 60%;
  }
}
